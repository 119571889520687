import Rails from "@rails/ujs"
import 'bootstrap/js/dist/modal';
document.addEventListener("turbolinks:load", function() {
  'use strict';
  var current_counter;

  const init_sign_contract_modal = (application_id) => {
    var modal_form = $("#sign_contract_verification_form_" + application_id);
    var submit_button = $("#sign_contract_" + application_id);

    function goToNextInput(e) {
      var key = e.which,
        t = $(e.target),
        sib = t.next('input');

      if (key != 9 && (key < 48 || key > 57)) {
        e.preventDefault();
        return false;
      }

      if (key === 9) {
        return true;
      }

      if (!sib || !sib.length) {
        sib = modal_form.find('input').eq(0);
      }
      sib.select().focus();
    }

    function goToPreviousInput(e) {
      var key = e.which,
        t = $(e.target),
        sib = t.prev('input');


      if (key === 8) {
        if (!sib || !sib.length) {
          sib = modal_form.find('input').eq(0);
        }
        sib.select().focus();
        sib.val('');
        t.val('');
      }

    }

    function onKeyDown(e) {
      var key = e.which;

      if (key === 9 || (key >= 48 && key <= 57)) {
        return true;
      }

      e.preventDefault();
      return false;
    }
    
    function onFocus(e) {
      $(e.target).select();
    }

    const enableSubmitButton = (e) => {
      var key = e.which,
      t = $(e.target);

      if (t.attr('name') == "code_input_6") {
        submit_button.removeClass("disabled")
      }
    }

    modal_form.on('keyup', 'input', goToNextInput);
    modal_form.on('keyup', 'input', goToPreviousInput);
    modal_form.on('keyup', 'input', enableSubmitButton);
    modal_form.on('keydown', 'input', onKeyDown);
    modal_form.on('click', 'input', onFocus);

    var time_sent = Date.now();
    current_counter = setInterval(function() {
      let time_passed = Math.floor((time_sent - Date.now() + 300000)/1000);
      if (time_passed > 0) {
        // count down
        $("#timer_valid_msg_" + application_id).show();
        $("#timer_invalid_msg_" + application_id).hide();
        document.getElementById("timer").innerHTML = time_passed;
      } else {
        // verification timeout
        $("#timer_valid_msg_" + application_id).hide();
        $("#timer_invalid_msg_" + application_id).show();
        document.getElementById("timer").innerHTML = "";
      }
    }, 1000)

    $("#resend_verification_code_form_" + application_id).submit(() => {
      clearInterval(current_counter);
      $(".sign_contract_input").val('');
      time_sent = Date.now();
      current_counter = setInterval(function() {
        let time_passed = Math.floor((time_sent - Date.now() + 300000)/1000);
        if (time_passed > 0) {
          // count down
          $("#timer_valid_msg_" + application_id).show();
          $("#timer_invalid_msg_" + application_id).hide();
          document.getElementById("timer").innerHTML = time_passed;
        } else {
          // verification timeout
          $("#timer_valid_msg_" + application_id).hide();
          $("#timer_invalid_msg_" + application_id).show();
          document.getElementById("timer").innerHTML = "";
        }
      }, 1000);
    })
    submit_button.on("click", () => {
      var verification_form = document.getElementById("sign_contract_verification_form_" + application_id);
      Rails.fire(verification_form, 'submit');
    });

    $("#sign_contract_verification_form_" + application_id).on("ajax:success", (event) => {
      // console.log("success: " + event.detail);
      var application_id = event.detail[0];
      $('#sign_contract_modal_' + application_id).modal('hide')
      
    }).on("ajax:error", (event) => {
      // console.log("error")
      var application_id = event.detail[0];
      $("#invalid_verification_code_"+ application_id).removeClass("d-none")
      // document.getElementById("loan_acceptance_modal_button_" + application_id).click();
      // var modal_form = $("#sign_contract_verification_form_" + application_id);
        // $("#update_loan_information_form").append "<p>ERROR</p>"
    });
  };


  $(".loan_acceptance_modal_button").each((index, value) => {
    var application_id = value.getAttribute("application_id");
    value.addEventListener("click", function(){
      // if (!value.getAttribute("data-clicked")) {
        // value.setAttribute("data-clicked", true);
      init_sign_contract_modal(application_id);
      var verification_code_form = document.getElementById("resend_verification_code_form_" + application_id);
      Rails.fire(verification_code_form, 'submit');
      var first_input = $("#sign_contract_verification_form_" + application_id +" input.form-control[name='code_input_1']");
      first_input[0].focus();
      // }
    });
  });
  $(".sign_contract_modal").on("hidden.bs.modal", function (e) {
      // var verification_code_form = document.getElementById("resend_verification_code_form_" + application_id);
      // Rails.fire(verification_code_form, 'submit');
      // var first_input = $("#sign_contract_verification_form_" + application_id +" input.form-control[name='code_input_1']");
      // first_input[0].focus();
      clearInterval(current_counter);
      $(".sign_contract_input").val('');

  });
  


})